<template>
	<div class="announcement">
		<div class="center-container">
			<div class="left-content">
				<div class="s-title s-title-b">
					<span class="decoration"></span>
					<span class="title">交易信息</span>
				</div>
				<div class="change">
					<div
						class="change-item"
						v-for="(item, index) in types"
						:key="index"
						:class="{ active: currentIndex == index }"
						@click="changeType(item, index)"
					>
						<span>{{ item.name }}</span>
					</div>
				</div>
			</div>
			<div class="right-content">
				<div class="s-title s-title-b">
					<span>当前位置：</span>
					<span>交易信息</span> >
					<span>{{ currentType.name }}</span>
				</div>
				<div class="info">
					<div class="query-card">
						<el-form class="query-form" :model="form" label-position="left" inline ref="form">
							<el-form-item label="项目编号" prop="code" v-if="currentIndex != '2'">
								<el-input v-model="form.code"></el-input>
							</el-form-item>
							<el-form-item label="关键字" prop="keyword">
								<el-input v-model="form.keyword"></el-input>
							</el-form-item>
							<div class="button" style="margin-top: 0">
								<el-form-item>
									<el-button type="primary" class="query-btn" @click="query">查询</el-button>
									<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
								</el-form-item>
							</div>
						</el-form>
					</div>

					<div class="table-card">
						<el-table
							border
							class="table-head-blue"
							:class="{ choose: currentIndex == 0 || currentIndex == 1 }"
							:data="tableData"
							style="width: 100%"
							@row-click="handleRowClick"
						>
							<el-table-column
								v-for="(item, index) in columns"
								:key="index"
								align="center"
								:prop="item.prop"
								:label="item.label"
							>
								<template slot-scope="scope">
									<span v-if="item.template">{{ scope.row.zxqwjg }} - {{ scope.row.zdqwjg }}</span>
									<span v-else>{{ scope.row[item.prop] }}</span>
								</template>
							</el-table-column>
						</el-table>
						<div class="pagenation">
							<el-pagination
								background
								layout="total, slot, prev, pager, next, slot"
								:current-page="currentPage"
								:page-size="pageSize"
								:total="total"
								@current-change="currentChange"
							>
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getNeedsAnnouncement,
	getSuccessAnnouncement,
	getSupervisionAnnouncement,
	getAuditResAnnouncement,
} from '../../api/announcement.js'
import { getRegionList } from '../../api/projectManage'
import { split } from '../../js/utils/util'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			types: [
				{ name: '供需信息公告', type: 0 },
				{ name: '成交信息公示', type: 1 },
				{ name: '通知公告', type: 4 },
			],
			currentIndex: 0,
			currentType: {},
			form: { code: '', keyword: '' },
			columns: [],
			tableData: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			regionOptions: [],
			props: {
				label: 'xzqmc',
				children: 'list',
				value: 'xzqbh',
				checkStrictly: true,
				emitPath: false,
			},
		}
	},
	watch: {
		$route: {
			handler: function () {
				let index = Number(this.$route.query.index)
				this.changeType(this.types[index], index)
			},
			immediate: true,
		},
		topSelectAreacode: function () {
			if (this.currentIndex == '0') {
				this.initNeedsAnnouncement()
			}
			if (this.currentIndex == '1') {
				this.initSuccessAnnouncement()
			}
			if (this.currentIndex == '2') {
				this.initSupervisionAnnouncement()
			}
		},
	},
	created() {
		this.getRegions()
	},
	computed: mapGetters(['topSelectAreacode']),
	methods: {
		async getRegions() {
			// 获取信息
			let res = await getRegionList()
			let { status, data } = res
			if (status) {
				let temp = data
				this.regionOptions = split(temp)
			}
		},
		changeType(item, index) {
			// 切换类型
			this.currentIndex = index
			this.currentType = item
			this.$router.push({ name: 'announcement', query: { index: index } })
			this.form = { code: '', keyword: '' }
			this.tableData = []
			this.currentPage = 1
			switch (index) {
				case 0:
					// 需求信息公告
					this.initNeedsAnnouncement()
					break
				case 1:
					// 成交信息公告
					this.initSuccessAnnouncement()
					break
				case 2:
					// 资金监管公示
					this.initSupervisionAnnouncement()
					break
				case 3:
					// 审核备案公示
					this.initAuditResAnnouncement()
					break
				default:
					break
			}
		},
		query(type) {
			// 查询
			if (!type) {
				this.currentPage = 1
			}
			switch (this.currentIndex) {
				case 0:
					// 需求信息公告
					this.initNeedsAnnouncement()
					break
				case 1:
					// 成交信息公告
					this.initSuccessAnnouncement()
					break
				case 2:
					// 资金监管公示
					this.initSupervisionAnnouncement()
					break
				case 3:
					// 审核备案公示
					this.initAuditResAnnouncement()
					break
				default:
					break
			}
		},
		clear() {
			// 清空
			this.currentPage = 1
			this.form = {}
			this.query()
		},
		async initNeedsAnnouncement() {
			// 需求信息公告
			let params = {
				xzqbh: this.topSelectAreacode,
				keyword: this.form.keyword,
				current: this.currentPage,
				size: this.pageSize,
        xmbh: this.form.code,
			}
			let res = await getNeedsAnnouncement(params)
			let { status, data, message } = res
			if (status == 200) {
				this.columns = [
					{ label: '项目编号', prop: 'xmbh' },
					{ label: '项目名称', prop: 'xmmc' },
					{ label: '公告开始时间', prop: 'ggkssj' },
					{ label: '公告截止时间', prop: 'ggjzsj' },
				]
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
		async initSuccessAnnouncement() {
			// 成交公示
			let params = {
				xmbh: this.form.code,
				keyword: this.form.keyword,
				current: this.currentPage,
				size: this.pageSize,
				xzqbh: this.topSelectAreacode,
			}
			let res = await getSuccessAnnouncement(params)
			let { status, data, message } = res
			if (status) {
				this.columns = [
					{ label: '项目编号', prop: 'xmbh' },
					{ label: '项目名称', prop: 'xmmc' },
					{ label: '公示开始时间', prop: 'cjgskssj' },
					{ label: '公示截止时间', prop: 'cjgsjzsj' },
				]
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
		async initSupervisionAnnouncement() {
			// 资金监管
			let params = {
				// xmbh: this.form.code,
				areaCode: this.topSelectAreacode,
				keyword: this.form.keyword,
				current: this.currentPage,
				size: this.pageSize,
				catalog: '0203',
			}
			let res = await getSupervisionAnnouncement(params)
			let { code, data, msg } = res
			if (code == 0) {
				this.columns = [
					{ label: '标题', prop: 'title' },
					// { label: '描述', prop: 'articleDesc' },
					{ label: '发布时间', prop: 'createTime' },
					// { label: '内容', prop: 'content' },
				]
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
		async initAuditResAnnouncement() {
			// 审核备案结果公告
			let params = {
				xmbh: this.form.code,
				keyword: this.form.keyword,
				pnum: this.currentPage,
				psize: this.pageSize,
			}
			let res = await getAuditResAnnouncement(params)
			let { status, data, dataNum } = res
			if (status) {
				this.columns = [
					{ label: '项目编号', prop: 'xmbh' },
					{ label: '项目名称', prop: 'xmmc' },
					{ label: '公示开始时间', prop: 'bagskssj' },
					{ label: '公示截止时间', prop: 'bagsjzsj' },
				]
				this.tableData = data
				this.total = dataNum
			}
		},
		currentChange(val) {
			// 切换分页
			this.currentPage = val
			this.query('change')
		},
		handleRowClass({ row, rowIndex }) {
			// 行樣式
			if (rowIndex % 2 === 0) {
				return 'odd'
			}
		},
		handleRowClick(row, column) {
			// 点击行事件
			switch (this.currentType.type) {
				case 0:
					// 需求信息公示详情
					this.$router.push({ name: 'projectDetail', query: { c: row.xmbh } })
					break
				case 1:
					// 成交公示详情
					this.$router.push({ name: 'projectDetail', query: { c: row.xmbh, isCj: true } })
					break
				case 4:
					// 通知公告
					this.$router.push({ name: 'newsPage', query: { c: row.articleId } })
					break
			}
		},
	},
}
</script>

<style lang="less" scoped>
.announcement {
	// background: #fff;
	padding-top: 20px;
	// min-height: 1092px;
	padding-bottom: 90px;
	.query-form {
		display: flex;
		align-items: center;
	}
	.query-btn {
		width: 126px;
		height: 34px;
		padding-top: 9px;
	}
	.query-btn:last-child {
		width: 70px;
	}
}
/deep/.el-form-item {
	display: flex !important;
	align-items: center;
	margin-bottom: 0;
}
.change-item {
	text-align: center;
}
</style>

<style lang="less">
.announcement {
	.choose {
		.el-table__row {
			cursor: pointer;
		}
	}
	.pagenation {
		.el-pagination.is-background .el-pager li:not(.disabled).active {
			border-radius: 0;
		}
	}
}
</style>
